import React, { useState } from "react"
import styled from "styled-components"
import axios from "axios"
import { navigate } from "gatsby"
import Page from "../components/page"
import Wrap from "../components/wrap"
import Section from "../components/section"
import imgBanner from "../images/banner-free-lesson.jpg"
import imgFlow2 from "../images/free-lesson-flow-2.jpg"
import imgFlow3 from "../images/free-lesson-flow-3.jpg"
import imgFlow4 from "../images/free-lesson-flow-4.jpg"

const PageLesson = () => {
  const [formState, setFormState] = useState({
    name: "",
    furigana: "",
    email: "",
    phone: "",
    age: "",
    course: "",
    time1: "",
    time2: "",
    introduction: "",
    message: "",
  })
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState("")

  const updateFormState = (field, value) => {
    const newFormState = { ...formState }
    newFormState[field] = value
    setFormState(newFormState)
  }

  const handleSubmit = e => {
    e.preventDefault()
    setSubmitting(true)

    const formData = {
      userId: new Date().getTime(),
      ...formState,
    }

    axios({
      method: "post",
      url: "https://api.tryhard.me/tda/lesson.php",
      // url: "http://localhost:8888/lesson.php",
      data: { ...formData },
    })
      .then(function (response) {
        // handle success
        navigate("/thanks-lesson", { state: { userId: formData.userId } })
      })
      .catch(function (error) {
        // handle error
        console.log(error)
        setError("エラーが発生しました。")
      })
  }

  return (
    <Page title="無料体験レッスン">
      <Wrap>
        <h2>無料体験お申し込みフォーム</h2>
        <form onSubmit={handleSubmit}>
          <Form>
            <Field>
              <label htmlFor="name">
                <div className="label">
                  <span>お名前</span> <span className="required">必須</span>
                </div>
                <div className="input">
                  <input
                    name="name"
                    type="text"
                    id="name"
                    value={formState.name}
                    onChange={e => updateFormState("name", e.target.value)}
                    required={true}
                  />
                </div>
              </label>
            </Field>
            <Field>
              <label htmlFor="furigana">
                <div className="label">
                  <span>フリガナ</span> <span className="required">必須</span>
                </div>
                <div className="input">
                  <input
                    name="furigana"
                    type="text"
                    id="furigana"
                    value={formState.furigana}
                    onChange={e => updateFormState("furigana", e.target.value)}
                    required={true}
                  />
                </div>
              </label>
            </Field>
            <Field>
              <label htmlFor="email">
                <div className="label">
                  <span>メールアドレス</span>{" "}
                  <span className="required">必須</span>
                </div>
                <div className="input">
                  <input
                    name="email"
                    type="text"
                    id="email"
                    value={formState.email}
                    onChange={e => updateFormState("email", e.target.value)}
                    required={true}
                  />
                </div>
              </label>
            </Field>
            <Field>
              <label htmlFor="phone">
                <div className="label">
                  <span>電話番号</span> <span className="required">必須</span>
                </div>
                <div className="input">
                  <input
                    name="phone"
                    type="text"
                    id="phone"
                    value={formState.phone}
                    onChange={e => updateFormState("phone", e.target.value)}
                    required={true}
                  />
                </div>
              </label>
            </Field>
            <Field>
              <label htmlFor="age">
                <div className="label">
                  <span>年齢</span>
                </div>
                <div className="input">
                  <select
                    name="age"
                    id="age"
                    value={formState.age}
                    onChange={e => updateFormState("age", e.target.value)}
                  >
                    <option value="">年齢</option>
                    <option value="10代">10代</option>
                    <option value="20代">20代</option>
                    <option value="30代">30代</option>
                    <option value="40代">40代</option>
                    <option value="50代">50代</option>
                    <option value="60代">60代</option>
                    <option value="70代">70代</option>
                    <option value="80代">80代</option>
                  </select>
                </div>
              </label>
            </Field>
            <Field>
              <label htmlFor="course">
                <div className="label">
                  <span>ご希望のコース</span>{" "}
                  <span className="required">必須</span>
                </div>
                <div className="input">
                  <select
                    name="course"
                    id="course"
                    value={formState.course}
                    onChange={e => updateFormState("course", e.target.value)}
                    required={true}
                  >
                    <option value="">ご希望のコース</option>
                    <option value="DTMコース">DTMコース</option>
                    <option value="DJコーズ">DJコース</option>
                    <option value="MCコース">MCコース</option>
                    <option value="KIDSコース">Kidsコース</option>
                    <option value="DJ-ZERO PROJECTコース">DJ-ZERO PROJECTコース</option>
                  </select>
                </div>
              </label>
            </Field>
            <Field className="time">
              <label htmlFor="time1">
                <div className="label">
                  <div>
                    <span>希望日時(12:00-22:00) </span>{" "}
                    <span className="required">必須</span>
                  </div>
                  <div>
                    ご予約の方は記入してください
                    <br />
                    ※例：4/10（金）18:00
                  </div>
                </div>
                <div className="input">
                  <input
                    name="time1"
                    type="text"
                    id="time1"
                    value={formState.time1}
                    onChange={e => updateFormState("time1", e.target.value)}
                    required={true}
                  />
                  <input
                    name="time1"
                    type="text"
                    id="time2"
                    value={formState.time2}
                    onChange={e => updateFormState("time2", e.target.value)}
                  />
                </div>
              </label>
            </Field>
            <Field>
              <label htmlFor="introduction">
                <div className="label">
                  <span>ご紹介者様のお名前</span>
                </div>
                <div className="input">
                  <input
                    name="introduction"
                    type="text"
                    id="introduction"
                    value={formState.introduction}
                    onChange={e =>
                      updateFormState("introduction", e.target.value)
                    }
                  />
                </div>
              </label>
            </Field>
            <Field className="message">
              <label htmlFor="message">
                <div className="label">
                  <span>
                    ご不明点・ご質問などあれば
                    <br />
                    なんでもご記入ください
                  </span>
                </div>
                <div className="input">
                  <textarea
                    name="message"
                    type="text"
                    id="message"
                    value={formState.message}
                    onChange={e => updateFormState("message", e.target.value)}
                  />
                </div>
              </label>
            </Field>
            <div className="submit">
              <input
                type="submit"
                value="送信"
                disabled={
                  formState.name === "" ||
                  formState.furigana === "" ||
                  formState.email === "" ||
                  formState.phone === "" ||
                  formState.course === "" ||
                  formState.time1 === "" ||
                  submitting
                }
              />
            </div>
            {error && <div className="error">{error}</div>}
          </Form>
        </form>

        <Section>
          <Flow>
            <img src={imgBanner} alt="" />

            <div className="step step1">
              <h3 className="step__title">
                <span>① まずはお気軽にご予約ください</span>
              </h3>
              <div className="step__body">
                <p>
                  ご予約はTryHard DJ
                  Academyまでお電話かフォームにて承っております。
                  <br />
                  TEL 06-6226-8667(12時～22時まで)
                </p>
              </div>
            </div>

            <div className="step step2">
              <h3 className="step__title">
                <span>② 当スクールまでのアクセス方法</span>
              </h3>
              <div className="step__image">
                <img src={imgFlow2} alt="地図" />
              </div>
              <div className="step__body">
                <p>
                  堺筋線、または長堀鶴見緑地線「長堀橋駅」④番出口を出てすぐ右手、高吉堺筋ビル3階にあります。
                  <br />
                  （大阪市中央区島之内1-19-15 高吉堺筋ビル3F）12:00-22:00
                </p>
              </div>
            </div>

            <div className="step step3">
              <h3 className="step__title">
                <span>③ アンケートの記入</span>
              </h3>
              <div className="step__image">
                <img src={imgFlow3} alt="アンケートの記入" />
              </div>
              <div className="step__body">
                <p>
                  まずは、簡単なアンケートに答えてもらい、それを元にヒアリングしていきます！
                </p>
              </div>
            </div>

            <div className="step step4">
              <h3 className="step__title">
                <span>④ カウンセリング</span>
              </h3>
              <div className="step__image">
                <img src={imgFlow4} alt="カウンセリング" />
              </div>
              <div className="step__body">
                <p>
                  どのようなジャンルが好きか、何を使ってDJをするかなど、目指すDJプレイはどのような内容かによって、その人にあったDJスタイルを一緒に考えていきましょう！
                </p>
              </div>
            </div>
          </Flow>
        </Section>
      </Wrap>
    </Page>
  )
}

export default PageLesson

const Form = styled.div`
  .submit {
    text-align: center;

    input {
      display: inline-block;
      width: 280px;
      margin: 0 0 3rem;
      padding: 0.5em;
      border: 0;
      border-radius: 0.25rem;
      background: ${({ theme }) => theme.yellow};
      color: #222;
      font-weight: 700;
      transition: background 0.25s, color 0.25s;

      &:hover {
        background: ${({ theme }) => theme.red};
        color: white;
      }

      &[disabled] {
        background: #d2d2d2;
        color: #222;

        &:hover {
          background: #d2d2d2;
          color: #222;
        }
      }
    }
  }
`

const Field = styled.div`
  margin: 2rem 0;
  font-size: 0.875em;

  label {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    @media (max-width: 500px) {
      flex-direction: column;
    }
  }

  .label {
    width: 33%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: 500px) {
      width: 100%;
      margin: 0 0 0.5rem;
    }
  }

  .input {
    width: 67%;

    @media (max-width: 500px) {
      width: 100%;
    }

    input {
      width: 100%;

      &:nth-child(2) {
        margin: 0.5rem 0 0 0;
      }
    }

    textarea {
      width: 100%;
      height: 200px;
    }

    select {
      width: 100%;
    }
  }

  .required {
    display: inline-block;
    margin: 0 0 0 0.5em;
    padding: 0.1em 0.5em;
    border-radius: 0.25em;
    background: red;
    color: white;
  }

  &.time {
    .label {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      div:not(:nth-child(1)) {
        margin: 0.5rem 0 0 0;
      }
    }
  }

  &.message {
    .label {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
`

const Flow = styled.div`
  width: 100%;
  max-width: 750px;
  margin: 2rem auto;

  img {
    max-width: 100%;
  }

  .step {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 2rem auto;

    &__title {
      width: 100%;
      padding: 0.5em;
      background: #33322c;
      color: white;
      text-align: center;
      font-size: 1.25rem;
      font-weight: 700;
    }

    &__image {
      width: calc(50% - 0.5rem);

      @media (max-width: 500px) {
        width: 100%;
        margin: 0 0 1rem;
        text-align: center;
      }

      img {
        max-width: 100%;
      }
    }

    &__body {
      width: 100%;

      p {
        margin: 0 0 1rem;
      }
    }

    &__image + .step__body {
      width: calc(50% - 0.5rem);

      @media (max-width: 500px) {
        width: 100%;
      }
    }
  }
`
